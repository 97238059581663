import * as routes from '@/constants/routes'
import Confirm from '@/pages/auth/Confirm/Confirm.vue'
import RegistrationComplete from '@/pages/auth/RegistrationComplete/RegistrationComplete.vue'
import NotFound from '@/pages/NotFound/NotFound.vue'
import lazyLoadPage from '../lazyLoadPage'

export default [
  {
    path: '/',
    name: routes.LANDING,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-landing" */ '@/pages/Landing/Landing.vue')),
    meta: {
      withoutLayout: true
    },
    props: route => {
      const {
        title,
        orderField,
        role,
        orderType,
        page,
      } = route.query
      return {
        title,
        orderField,
        role,
        orderType,
        page,
      }
    }
  },
  {
    path: '/hire-talents',
    name: routes.HIRE_LANDING,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-landing" */ '@/pages/HireLanding/HireLanding.vue')),
    meta: {
      withoutLayout: true
    }
  },
  {
    path: '/index',
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-landing-copy" */ '@/pages/LandingCopy/Landing.vue')),
    meta: {
      withoutLayout: true
    }
  },
  {
    path: '/main',
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-landing-copy" */ '@/pages/LandingCopy/Landing.vue')),
    meta: {
      withoutLayout: true
    }
  },
  {
    path: '/blog',
    name: routes.BLOG,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-blog" */ '@/pages/blog/BlogNew/Blog.vue')),
    meta: {
      layoutProps: {
        publicOnly: true
      }
    }
  },
  {
    path: '/blog/:slug',
    name: routes.POST,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-blog" */ '@/pages/blog/PostNew/Post.vue')),
    meta: {
      layoutProps: {
        publicOnly: true
      }
    }
  },
  {
    path: '/registration-complete',
    name: routes.REGISTRATION_COMPLETE,
    component: RegistrationComplete,
    meta: {
      layoutProps: {
        publicOnly: true
      }
    }
  },
  {
    path: '/auth/confirmation',
    name: routes.AUTH_CONFIRM,
    component: Confirm,
    props: (route) => ({
      token: route.query.token,
      type: 'sign-up'
    }),
    meta: {
      layoutProps: {
        publicOnly: true,
      }
    }
  },
  {
    path: '/auth/email-confirmation',
    name: routes.EMAIL_CONFIRM,
    component: Confirm,
    props: (route) => ({
      token: route.query.token,
      type: 'email'
    }),
    meta: {
      withoutLayout: true
    }
  },
  {
    path: '/auth/subscribe-confirmation',
    name: routes.SUBSCRIBE_CONFIRMATION,
    component: Confirm,
    props: (route) => ({
      token: route.query.token,
      type: 'subscription'
    }),
    meta: {
      withoutLayout: true
    }
  },
  {
    path: '/auth/social',
    name: routes.SOCIAL_CONFIRM,
    component: Confirm,
    props: (route) => ({
      token: route.query.token,
      type: 'social'
    }),
    meta: {
      withoutLayout: true
    }
  },
  {
    path: '/premium',
    name: routes.PREMIUM,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-premium" */ '@/pages/Premium/Premium.vue')),
    meta: {
      layoutProps: {
        publicOnly: true,
      }
    }
  },
  {
    path: '/freelancers',
    redirect: { name: routes.FREELANCERS_LIST },
  },
  {
    path: '/freelancers/:skill',
    redirect: { name: routes.FREELANCERS_LIST_BY_SKILL },
  },
  {
    path: '/hire',
    name: routes.FREELANCERS_LIST,
    // eslint-disable-next-line max-len
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-freelancers" */ '@/pages/BrowseFreelancers/BrowseFreelancers.vue')),
  },
  {
    path: '/freelancers/users/id:id(\\d+)',
    name: routes.FREELANCER_PROFILE,
    // eslint-disable-next-line max-len
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-freelancers" */ '@/pages/FreelancerProfile/FreelancerProfile.vue')),
  },
  {
    path: '/hire/:skill',
    name: routes.FREELANCERS_LIST_BY_SKILL,
    // eslint-disable-next-line max-len
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-freelancers" */ '@/pages/BrowseFreelancers/BrowseFreelancers.vue')),
  },
  {
    path: '/customers/users/id:id(\\d+)',
    name: routes.CUSTOMER_PROFILE,
    component: () =>
      lazyLoadPage(import(/* webpackChunkName: "public-page-customers" */ '@/pages/CustomerProfile/CustomerProfile.vue')),
  },
  {
    path: '/jobs',
    name: routes.BROWSE_JOBS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Jobs/BrowseJobs/BrowseJobs.vue')),
    meta: {
      layoutProps: {
        classes: 'browse-jobs-layout'
      }
    }
  },
  {
    path: '/jobs/add',
    name: routes.JOB_ADD,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Jobs/JobAddEdit/JobAddEdit.vue')),
    meta: {
      requiresCustomer: true,
      redirectRoute: routes.SERVICE_ADD,
    }
  },
  {
    path: '/jobs/id:id(\\d+)',
    name: routes.JOB_DETAILS_BY_ID,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Jobs/JobDetails/JobDetailsView/JobDetailsView.vue')
    ),
  },
  {
    path: '/jobs/:slug-:id(\\d+)',
    name: routes.JOB_DETAILS,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Jobs/JobDetails/JobDetailsView/JobDetailsView.vue')
    ),
  },
  {
    path: '/jobs/:skill',
    name: routes.BROWSE_JOBS_BY_SKILL,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-jobs" */ '@/pages/Jobs/BrowseJobs/BrowseJobs.vue')),
    meta: {
      layoutProps: {
        classes: 'browse-jobs-layout'
      }
    }
  },
  {
    path: '/skills',
    name: routes.SKILLS_LIST,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-skills" */ '@/pages/Skills/Skills.vue')),
    meta: {
      layoutProps: {
        publicOnly: true,
      }
    }
  },
  {
    path: '/about-us',
    name: routes.ABOUT_US,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-about" */ '@/pages/AboutUs/AboutUs.vue')),
    meta: {
      layoutProps: {
        publicOnly: true,
      },
    },
  },
  {
    path: '/gigs',
    name: routes.SERVICES,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-gigs" */ '@/pages/Gigs/BrowseGigs/BrowseGigs.vue')),
    meta: {
      layoutProps: {
        classes: 'browse-gigs-layout'
      }
    }
  },
  {
    path: '/gigs/add',
    name: routes.SERVICE_ADD,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-gigs" */ '@/pages/Gigs/GigAddEdit/GigAddEdit.vue')),
    meta: {
      requiresFreelancer: true,
      redirectRoute: routes.JOB_ADD,
    },
  },
  {
    path: '/gigs/add/axie',
    redirect: { name: routes.SERVICE_ADD },
  },
  {
    path: '/gigs/id:id(\\d+)',
    name: routes.SERVICE_DETAILS_BY_ID,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-gigs" */ '@/pages/Gigs/GigDetails/GigDetails.vue')),
    meta: {
      layoutProps: {
        classes: 'gig-details-layout'
      }
    }
  },
  {
    path: '/gigs/:slug-:id(\\d+)',
    name: routes.SERVICE_DETAILS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-gigs" */ '@/pages/Gigs/GigDetails/GigDetails.vue')),
    meta: {
      layoutProps: {
        classes: 'gig-details-layout'
      }
    }
  },
  {
    path: '/gigs/:skill',
    name: routes.SERVICE_BY_SKILL,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-gigs" */ '@/pages/Gigs/BrowseGigs/BrowseGigs.vue')),
    meta: {
      layoutProps: {
        classes: 'browse-gigs-layout'
      }
    }
  },
  {
    path: '/services',
    redirect: { name: routes.SERVICES },
  },
  {
    path: '/services/id:id(\\d+)',
    redirect: { name: routes.SERVICE_DETAILS_BY_ID },
  },
  {
    path: '/services/:skill',
    redirect: { name: routes.SERVICE_BY_SKILL },
  },
  {
    path: '/vacancies',
    name: routes.VACANCIES,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/BrowseVacancies/BrowseVacancies.vue')
    ),
  },
  {
    path: '/vacancies/id:id(\\d+)',
    name: routes.VACANCY_DETAILS_BY_ID,
    component: () => lazyLoadPage(
      // eslint-disable-next-line max-len
      import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/VacancyDetailsNew/VacancyDetailsView/VacancyDetailsView.vue')
    ),
  },
  {
    path: '/vacancies/:slug-:id(\\d+)',
    name: routes.VACANCY_DETAILS,
    component: () => lazyLoadPage(
      // eslint-disable-next-line max-len
      import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/VacancyDetailsNew/VacancyDetailsView/VacancyDetailsView.vue')
    ),
  },
  {
    path: '/vacancies/:slug-:id(\\d+)/apply',
    name: routes.VACANCY_EXTERNAL_APPLY,
    component: () => lazyLoadPage(
      // eslint-disable-next-line max-len
      import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/ExternalVacancyApplying/ExternalVacancyApplying.vue')
    ),
    meta: {
      withoutLayout: true
    }
  },
  {
    path: '/vacancies/add',
    name: routes.VACANCY_ADD,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/AddEditVacancyNew/AddEditVacancyNew.vue')
    ),
    meta: {
      requiresCustomer: true,
      redirectRoute: routes.SERVICE_ADD,
    }
  },
  {
    path: '/vacancies/roles/:role',
    name: routes.BROWSE_VACANCIES_BY_ROLE,
    component: () =>
      lazyLoadPage(import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/BrowseVacancies/BrowseVacancies.vue')),
  },
  {
    path: '/vacancies/:skill',
    name: routes.BROWSE_VACANCIES_BY_SKILL,
    component: () =>
      lazyLoadPage(import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/BrowseVacancies/BrowseVacancies.vue')),
  },
  {
    path: '/vacancies/:skill/:country',
    name: routes.BROWSE_VACANCIES_BY_SKILL_COUNTRY,
    component: () =>
      lazyLoadPage(import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/BrowseVacancies/BrowseVacancies.vue')),
  },
  {
    path: '/vacancies/:skill/:country/:city',
    name: routes.BROWSE_VACANCIES_BY_SKILL_COUNTRY_CITY,
    component: () =>
      lazyLoadPage(import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/Vacancies/BrowseVacancies/BrowseVacancies.vue')),
  },
  {
    path: '/job-mining',
    name: routes.JOB_MINING,
    component: () => lazyLoadPage(import(/* webpackChunkName: "public-page-mining" */ '@/pages/JobMining/JobMining.vue')),
    meta: {
      layoutProps: {
        publicOnly: true
      }
    }
  },
  {
    path: '/post-a-job',
    name: routes.POST_A_JOB,
    component: () => lazyLoadPage(
      import(/* webpackChunkName: "public-page-vacancies" */ '@/pages/JobTypeSelect/JobTypeSelect.vue')
    ),
    meta: {
      requiresCustomer: true,
      redirectRoute: routes.SERVICE_ADD,
    }
  },
  {
    path: '/leave',
    name: routes.LEAVE,
    component: () => lazyLoadPage(import(/* webpackChunkName: "private-pages" */ '@/pages/LeavingPage/LeavingPage.vue')),
  },
  {
    path: '/web3-blockchain-developers-salaries',
    name: routes.SALARY_STATISTICS,
    component: () => lazyLoadPage(import(/* webpackChunkName: "salary-pages" */ '@/pages/SalaryStatistics/SalaryStatistics.vue')),
    meta: {
      layoutProps: {
        publicOnly: true,
        classes: 'salary-statistics-layout',
      }
    }
  },
  {
    path: '/*',
    name: routes.NOT_FOUND,
    component: NotFound,
  },
]
