export default class DateAmountStatistic {
  date: string
  value: number
  constructor (props: Partial<DateAmountStatistic>) {
    Object.assign(this, props)
  }
  static fromServer (props: Partial<DateAmountStatistic>) {
    return new DateAmountStatistic(props)
  }
}
