import { Module, Commit } from 'vuex'
import { RootState } from '@/store'
import { getModerationPage } from '@/api/pages'
import LoadableModel from '@/models-ts/LoadableModel'
import DateAmountStatistic from '@/models-ts/salaryStatistic/DateAmountStatistic'
import SalaryStatistic from '@/models-ts/salaryStatistic/SalaryStatistic'
import { ISalaryState } from './types'
import { parseJson } from '@/utils/parser'

const getInitialState = (): ISalaryState => ({
  salaryData: new LoadableModel({
    value: {
      applications: [],
      jobs: [],
      trends: [],
      salaries: [],
      seniority: [],
      locations: [],
    }
  }),
  prefetched: false,
})

export default (): Module<ISalaryState, RootState> => ({
  namespaced: true,
  state: getInitialState(),
  mutations: {
    beforeReady (state) {
      state.salaryData = new LoadableModel(state.salaryData)
    },
    setPrefetched (state, flag: boolean) {
      state.prefetched = flag
    },
    setLoading (state) {
      state.salaryData.loading()
    },
    setLoaded (state, { salaries, jobs, applications, trends, seniority, locations }: {
      salaries: Array<SalaryStatistic>,
      applications: Array<DateAmountStatistic>,
      jobs: Array<DateAmountStatistic>,
      trends: Array<DateAmountStatistic>,
      seniority: Array<SalaryStatistic>,
      locations: Array<SalaryStatistic>
    }) {
      state.salaryData.loaded({
        salaries, jobs, applications, trends, seniority, locations
      })
    }
  },
  actions: {
    async getSalaryStatisticsPage ({ commit }) {
      commit('setLoading')
      const { content } = await getModerationPage('salary_statistics_page')
      const parsed = parseJson(content)
      const data = { applications: [], jobs: [], trends: [], salaries: [], seniority: [], locations: [] }
      if (parsed.salaries) {
        data.salaries = parsed.salaries.map(SalaryStatistic.fromServer)
      }
      if (parsed.applications) {
        data.applications = parsed.applications.map(DateAmountStatistic.fromServer)
      }
      if (parsed.jobs) {
        data.jobs = parsed.jobs.map(DateAmountStatistic.fromServer)
      }
      if (parsed.trends) {
        data.trends = parsed.trends.map(DateAmountStatistic.fromServer)
      }
      if (parsed.seniority) {
        data.seniority = parsed.seniority.map(SalaryStatistic.fromServer)
      }
      if (parsed.locations) {
        data.locations = parsed.locations.map(SalaryStatistic.fromServer)
      }
      commit('setLoaded', data)
    },
  }
})
