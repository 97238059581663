import Vue, { PropType } from 'vue'

export interface StatisticTableColumn {
  name: string
  title?: string
  align?: 'left' | 'right' | 'center'
  width?: string
}

export default Vue.extend({
  name: 'lx-statistics-table',
  props: {
    columns: Array as PropType<StatisticTableColumn[]>,
    rows: Array as PropType<Object[]>,
  },
})
