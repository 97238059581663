export default class SalaryStatistic {
  position: string
  min: number
  avg: number
  max: number
  isConclusion?: Boolean

  constructor (props: Partial<SalaryStatistic>) {
    Object.assign(this, props)
  }
  static fromServer (props: Partial<SalaryStatistic>) {
    return new SalaryStatistic(props)
  }
}
