// @ts-ignore
import NoSsr from 'vue-no-ssr'
import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue'

let components = {
  NoSsr
}

if (process.client || typeof process.client === 'undefined') {
  // @ts-ignore
  components['bar-chart-js'] = () => ({
    component: import(/* webpackChunkName: "ckeditor" */ '@/components/BarChart/BarChartJs/BarChartJs.vue'),
    loading: SkeletonLoader
  })
}

export default {
  name: 'lx-bar-chart',
  components,
  props: ['value'],
}
